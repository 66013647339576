import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { ColorLooper } from 'docs/helpers/ColorLooper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Paper } from '../Paper';
import { ColoredPaper, StyledPaper } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Paper" title="Paper" mdxType="PageTitle" />
    <h2 {...{
      "id": "standard-paper"
    }}>{`Standard paper`}</h2>
    <h3 {...{
      "id": "extreme-paper"
    }}>{`Extreme paper`}</h3>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Paper bordered color=\"extreme\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="extreme" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "primary-paper"
    }}>{`Primary paper`}</h3>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Paper bordered color=\"primary\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="primary" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "secondary-paper"
    }}>{`Secondary paper`}</h3>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Paper bordered color=\"secondary\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="secondary" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "tertiary-paper"
    }}>{`Tertiary paper`}</h3>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Paper bordered color=\"tertiary\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="tertiary" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "transparent-paper"
    }}>{`Transparent paper`}</h3>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Paper bordered color=\"transparent\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="transparent" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "accent-paper"
    }}>{`Accent paper`}</h3>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Paper bordered color=\"accent\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="accent" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "contrast-paper"
    }}>{`Contrast paper`}</h3>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <Paper bordered color=\"contrast\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="contrast" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "status-paper"
    }}>{`Status paper`}</h2>
    <h3 {...{
      "id": "success-paper"
    }}>{`Success paper`}</h3>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <Paper bordered color=\"success\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="success" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "warning-paper"
    }}>{`Warning paper`}</h3>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <Paper bordered color=\"warning\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="warning" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "danger-paper"
    }}>{`Danger paper`}</h3>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <Paper bordered color=\"danger\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="danger" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "scrollable-paper"
    }}>{`Scrollable paper`}</h2>
    <h3 {...{
      "id": "basic-scrollable-paper"
    }}>{`Basic scrollable paper`}</h3>
    <Playground __position={11} __code={'<ThemeWrapper\n  style={{\n    position: \'relative\',\n    maxHeight: \'100px\',\n    padding: 0,\n    overflow: \'hidden\',\n  }}\n>\n  <Paper scrollable color=\"primary\" style={{ height: \'100px\' }}>\n    <p>\n      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam sem,\n      tincidunt nec vehicula non, auctor ac nulla. Aenean accumsan mi et odio\n      tempus, ac consectetur lorem porta. Quisque fermentum porttitor laoreet.\n      Ut efficitur libero feugiat, suscipit ligula eget, ultricies turpis.\n      Nullam congue eu urna id blandit. Morbi sit amet lectus ac orci\n      venenatis pulvinar. Morbi rhoncus nec libero ac imperdiet. Vivamus sem\n      purus, pharetra vel lacus at, feugiat finibus libero. Fusce sapien\n      massa, eleifend ut fringilla et, pulvinar a tellus. Nulla nisi dui,\n      venenatis sed elementum luctus, sagittis sit amet neque. Class aptent\n      taciti sociosqu ad litora torquent per conubia nostra, per inceptos\n      himenaeos. Proin ultrices mauris dapibus justo ultricies varius. Cras\n      eleifend lorem lorem, eu semper ligula pharetra non. Donec eleifend\n      massa eu consequat pretium. Curabitur dui lorem, condimentum sed\n      interdum ac, dapibus eu purus.\n    </p>\n    <p>\n      Curabitur dolor arcu, elementum sit amet arcu non, eleifend molestie\n      sapien. Fusce ut volutpat nibh. Mauris ullamcorper ac ipsum ut pulvinar.\n      Duis a ipsum vitae enim mollis ornare. Pellentesque ut ex erat. Integer\n      id faucibus eros. Sed aliquam facilisis tellus, nec semper ante dapibus\n      ut. Ut imperdiet, metus sed volutpat venenatis, neque dolor egestas dui,\n      eget rhoncus dui eros ut enim.\n    </p>\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper style={{
        position: 'relative',
        maxHeight: '100px',
        padding: 0,
        overflow: 'hidden'
      }} mdxType="ThemeWrapper">
    <Paper scrollable color="primary" style={{
          height: '100px'
        }} mdxType="Paper">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam sem, tincidunt nec vehicula non, auctor ac
        nulla. Aenean accumsan mi et odio tempus, ac consectetur lorem porta. Quisque fermentum porttitor laoreet. Ut
        efficitur libero feugiat, suscipit ligula eget, ultricies turpis. Nullam congue eu urna id blandit. Morbi sit
        amet lectus ac orci venenatis pulvinar. Morbi rhoncus nec libero ac imperdiet. Vivamus sem purus, pharetra vel
        lacus at, feugiat finibus libero. Fusce sapien massa, eleifend ut fringilla et, pulvinar a tellus. Nulla nisi
        dui, venenatis sed elementum luctus, sagittis sit amet neque. Class aptent taciti sociosqu ad litora torquent
        per conubia nostra, per inceptos himenaeos. Proin ultrices mauris dapibus justo ultricies varius. Cras eleifend
        lorem lorem, eu semper ligula pharetra non. Donec eleifend massa eu consequat pretium. Curabitur dui lorem,
        condimentum sed interdum ac, dapibus eu purus.
      </p>
      <p>
        Curabitur dolor arcu, elementum sit amet arcu non, eleifend molestie sapien. Fusce ut volutpat nibh. Mauris
        ullamcorper ac ipsum ut pulvinar. Duis a ipsum vitae enim mollis ornare. Pellentesque ut ex erat. Integer id
        faucibus eros. Sed aliquam facilisis tellus, nec semper ante dapibus ut. Ut imperdiet, metus sed volutpat
        venenatis, neque dolor egestas dui, eget rhoncus dui eros ut enim.
      </p>
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "scrollable-paper-with-small-scrollbar"
    }}>{`Scrollable paper with small scrollbar`}</h3>
    <Playground __position={12} __code={'<ThemeWrapper\n  style={{\n    position: \'relative\',\n    maxHeight: \'100px\',\n    padding: 0,\n    overflow: \'hidden\',\n  }}\n>\n  <Paper\n    scrollable\n    color=\"primary\"\n    scrollbar=\"small\"\n    style={{ height: \'100px\' }}\n  >\n    <p>\n      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam sem,\n      tincidunt nec vehicula non, auctor ac nulla. Aenean accumsan mi et odio\n      tempus, ac consectetur lorem porta. Quisque fermentum porttitor laoreet.\n      Ut efficitur libero feugiat, suscipit ligula eget, ultricies turpis.\n      Nullam congue eu urna id blandit. Morbi sit amet lectus ac orci\n      venenatis pulvinar. Morbi rhoncus nec libero ac imperdiet. Vivamus sem\n      purus, pharetra vel lacus at, feugiat finibus libero. Fusce sapien\n      massa, eleifend ut fringilla et, pulvinar a tellus. Nulla nisi dui,\n      venenatis sed elementum luctus, sagittis sit amet neque. Class aptent\n      taciti sociosqu ad litora torquent per conubia nostra, per inceptos\n      himenaeos. Proin ultrices mauris dapibus justo ultricies varius. Cras\n      eleifend lorem lorem, eu semper ligula pharetra non. Donec eleifend\n      massa eu consequat pretium. Curabitur dui lorem, condimentum sed\n      interdum ac, dapibus eu purus.\n    </p>\n    <p>\n      Curabitur dolor arcu, elementum sit amet arcu non, eleifend molestie\n      sapien. Fusce ut volutpat nibh. Mauris ullamcorper ac ipsum ut pulvinar.\n      Duis a ipsum vitae enim mollis ornare. Pellentesque ut ex erat. Integer\n      id faucibus eros. Sed aliquam facilisis tellus, nec semper ante dapibus\n      ut. Ut imperdiet, metus sed volutpat venenatis, neque dolor egestas dui,\n      eget rhoncus dui eros ut enim.\n    </p>\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper style={{
        position: 'relative',
        maxHeight: '100px',
        padding: 0,
        overflow: 'hidden'
      }} mdxType="ThemeWrapper">
    <Paper scrollable color="primary" scrollbar="small" style={{
          height: '100px'
        }} mdxType="Paper">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam sem, tincidunt nec vehicula non, auctor ac
        nulla. Aenean accumsan mi et odio tempus, ac consectetur lorem porta. Quisque fermentum porttitor laoreet. Ut
        efficitur libero feugiat, suscipit ligula eget, ultricies turpis. Nullam congue eu urna id blandit. Morbi sit
        amet lectus ac orci venenatis pulvinar. Morbi rhoncus nec libero ac imperdiet. Vivamus sem purus, pharetra vel
        lacus at, feugiat finibus libero. Fusce sapien massa, eleifend ut fringilla et, pulvinar a tellus. Nulla nisi
        dui, venenatis sed elementum luctus, sagittis sit amet neque. Class aptent taciti sociosqu ad litora torquent
        per conubia nostra, per inceptos himenaeos. Proin ultrices mauris dapibus justo ultricies varius. Cras eleifend
        lorem lorem, eu semper ligula pharetra non. Donec eleifend massa eu consequat pretium. Curabitur dui lorem,
        condimentum sed interdum ac, dapibus eu purus.
      </p>
      <p>
        Curabitur dolor arcu, elementum sit amet arcu non, eleifend molestie sapien. Fusce ut volutpat nibh. Mauris
        ullamcorper ac ipsum ut pulvinar. Duis a ipsum vitae enim mollis ornare. Pellentesque ut ex erat. Integer id
        faucibus eros. Sed aliquam facilisis tellus, nec semper ante dapibus ut. Ut imperdiet, metus sed volutpat
        venenatis, neque dolor egestas dui, eget rhoncus dui eros ut enim.
      </p>
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "scrollable-paper-with-x-small-scrollbar"
    }}>{`Scrollable paper with x-small scrollbar`}</h3>
    <Playground __position={13} __code={'<ThemeWrapper\n  style={{\n    position: \'relative\',\n    maxHeight: \'100px\',\n    padding: 0,\n    overflow: \'hidden\',\n  }}\n>\n  <Paper\n    scrollable\n    color=\"primary\"\n    scrollbar=\"xsmall\"\n    style={{ height: \'100px\' }}\n  >\n    <p>\n      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam sem,\n      tincidunt nec vehicula non, auctor ac nulla. Aenean accumsan mi et odio\n      tempus, ac consectetur lorem porta. Quisque fermentum porttitor laoreet.\n      Ut efficitur libero feugiat, suscipit ligula eget, ultricies turpis.\n      Nullam congue eu urna id blandit. Morbi sit amet lectus ac orci\n      venenatis pulvinar. Morbi rhoncus nec libero ac imperdiet. Vivamus sem\n      purus, pharetra vel lacus at, feugiat finibus libero. Fusce sapien\n      massa, eleifend ut fringilla et, pulvinar a tellus. Nulla nisi dui,\n      venenatis sed elementum luctus, sagittis sit amet neque. Class aptent\n      taciti sociosqu ad litora torquent per conubia nostra, per inceptos\n      himenaeos. Proin ultrices mauris dapibus justo ultricies varius. Cras\n      eleifend lorem lorem, eu semper ligula pharetra non. Donec eleifend\n      massa eu consequat pretium. Curabitur dui lorem, condimentum sed\n      interdum ac, dapibus eu purus.\n    </p>\n    <p>\n      Curabitur dolor arcu, elementum sit amet arcu non, eleifend molestie\n      sapien. Fusce ut volutpat nibh. Mauris ullamcorper ac ipsum ut pulvinar.\n      Duis a ipsum vitae enim mollis ornare. Pellentesque ut ex erat. Integer\n      id faucibus eros. Sed aliquam facilisis tellus, nec semper ante dapibus\n      ut. Ut imperdiet, metus sed volutpat venenatis, neque dolor egestas dui,\n      eget rhoncus dui eros ut enim.\n    </p>\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper style={{
        position: 'relative',
        maxHeight: '100px',
        padding: 0,
        overflow: 'hidden'
      }} mdxType="ThemeWrapper">
    <Paper scrollable color="primary" scrollbar="xsmall" style={{
          height: '100px'
        }} mdxType="Paper">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam sem, tincidunt nec vehicula non, auctor ac
        nulla. Aenean accumsan mi et odio tempus, ac consectetur lorem porta. Quisque fermentum porttitor laoreet. Ut
        efficitur libero feugiat, suscipit ligula eget, ultricies turpis. Nullam congue eu urna id blandit. Morbi sit
        amet lectus ac orci venenatis pulvinar. Morbi rhoncus nec libero ac imperdiet. Vivamus sem purus, pharetra vel
        lacus at, feugiat finibus libero. Fusce sapien massa, eleifend ut fringilla et, pulvinar a tellus. Nulla nisi
        dui, venenatis sed elementum luctus, sagittis sit amet neque. Class aptent taciti sociosqu ad litora torquent
        per conubia nostra, per inceptos himenaeos. Proin ultrices mauris dapibus justo ultricies varius. Cras eleifend
        lorem lorem, eu semper ligula pharetra non. Donec eleifend massa eu consequat pretium. Curabitur dui lorem,
        condimentum sed interdum ac, dapibus eu purus.
      </p>
      <p>
        Curabitur dolor arcu, elementum sit amet arcu non, eleifend molestie sapien. Fusce ut volutpat nibh. Mauris
        ullamcorper ac ipsum ut pulvinar. Duis a ipsum vitae enim mollis ornare. Pellentesque ut ex erat. Integer id
        faucibus eros. Sed aliquam facilisis tellus, nec semper ante dapibus ut. Ut imperdiet, metus sed volutpat
        venenatis, neque dolor egestas dui, eget rhoncus dui eros ut enim.
      </p>
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "contained-scrollable-paper"
    }}>{`Contained scrollable paper`}</h3>
    <Playground __position={14} __code={'<ThemeWrapper\n  style={{ position: \'relative\', height: \'100px\', overflow: \'hidden\' }}\n>\n  <Paper contained scrollable color=\"primary\">\n    <p>\n      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam sem,\n      tincidunt nec vehicula non, auctor ac nulla. Aenean accumsan mi et odio\n      tempus, ac consectetur lorem porta. Quisque fermentum porttitor laoreet.\n      Ut efficitur libero feugiat, suscipit ligula eget, ultricies turpis.\n      Nullam congue eu urna id blandit. Morbi sit amet lectus ac orci\n      venenatis pulvinar. Morbi rhoncus nec libero ac imperdiet. Vivamus sem\n      purus, pharetra vel lacus at, feugiat finibus libero. Fusce sapien\n      massa, eleifend ut fringilla et, pulvinar a tellus. Nulla nisi dui,\n      venenatis sed elementum luctus, sagittis sit amet neque. Class aptent\n      taciti sociosqu ad litora torquent per conubia nostra, per inceptos\n      himenaeos. Proin ultrices mauris dapibus justo ultricies varius. Cras\n      eleifend lorem lorem, eu semper ligula pharetra non. Donec eleifend\n      massa eu consequat pretium. Curabitur dui lorem, condimentum sed\n      interdum ac, dapibus eu purus.\n    </p>\n    <p>\n      Curabitur dolor arcu, elementum sit amet arcu non, eleifend molestie\n      sapien. Fusce ut volutpat nibh. Mauris ullamcorper ac ipsum ut pulvinar.\n      Duis a ipsum vitae enim mollis ornare. Pellentesque ut ex erat. Integer\n      id faucibus eros. Sed aliquam facilisis tellus, nec semper ante dapibus\n      ut. Ut imperdiet, metus sed volutpat venenatis, neque dolor egestas dui,\n      eget rhoncus dui eros ut enim.\n    </p>\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper style={{
        position: 'relative',
        height: '100px',
        overflow: 'hidden'
      }} mdxType="ThemeWrapper">
    <Paper contained scrollable color="primary" mdxType="Paper">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam sem, tincidunt nec vehicula non, auctor ac
        nulla. Aenean accumsan mi et odio tempus, ac consectetur lorem porta. Quisque fermentum porttitor laoreet. Ut
        efficitur libero feugiat, suscipit ligula eget, ultricies turpis. Nullam congue eu urna id blandit. Morbi sit
        amet lectus ac orci venenatis pulvinar. Morbi rhoncus nec libero ac imperdiet. Vivamus sem purus, pharetra vel
        lacus at, feugiat finibus libero. Fusce sapien massa, eleifend ut fringilla et, pulvinar a tellus. Nulla nisi
        dui, venenatis sed elementum luctus, sagittis sit amet neque. Class aptent taciti sociosqu ad litora torquent
        per conubia nostra, per inceptos himenaeos. Proin ultrices mauris dapibus justo ultricies varius. Cras eleifend
        lorem lorem, eu semper ligula pharetra non. Donec eleifend massa eu consequat pretium. Curabitur dui lorem,
        condimentum sed interdum ac, dapibus eu purus.
      </p>
      <p>
        Curabitur dolor arcu, elementum sit amet arcu non, eleifend molestie sapien. Fusce ut volutpat nibh. Mauris
        ullamcorper ac ipsum ut pulvinar. Duis a ipsum vitae enim mollis ornare. Pellentesque ut ex erat. Integer id
        faucibus eros. Sed aliquam facilisis tellus, nec semper ante dapibus ut. Ut imperdiet, metus sed volutpat
        venenatis, neque dolor egestas dui, eget rhoncus dui eros ut enim.
      </p>
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "paper-layout"
    }}>{`Paper layout`}</h2>
    <h3 {...{
      "id": "flexible-paper"
    }}>{`Flexible paper`}</h3>
    <Playground __position={15} __code={'<ThemeWrapper style={{ position: \'relative\', height: \'100px\' }}>\n  <Paper flexible color=\"primary\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper style={{
        position: 'relative',
        height: '100px'
      }} mdxType="ThemeWrapper">
    <Paper flexible color="primary" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "full-paper"
    }}>{`Full paper`}</h3>
    <Playground __position={16} __code={'<ThemeWrapper style={{ position: \'relative\', height: \'100px\' }}>\n  <Paper full color=\"primary\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper style={{
        position: 'relative',
        height: '100px'
      }} mdxType="ThemeWrapper">
    <Paper full color="primary" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "contained-paper"
    }}>{`Contained paper`}</h3>
    <Playground __position={17} __code={'<ThemeWrapper style={{ position: \'relative\', height: \'100px\' }}>\n  <Paper contained color=\"primary\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper style={{
        position: 'relative',
        height: '100px'
      }} mdxType="ThemeWrapper">
    <Paper contained color="primary" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "colored-paper"
    }}>{`Colored paper`}</h2>
    <Playground __position={18} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorLooper\n      group=\"primary\"\n      render={({ id }) => (\n        <ColoredPaper\n          bordered\n          scrollable\n          colorId={id}\n          container=\"panel\"\n          key={id}\n          style={{ height: \'45px\' }}\n        >\n          <div style={{ height: \'60px\' }}>\n            The quick brown fox jumped over the lazy dog {id}\n          </div>\n        </ColoredPaper>\n      )}\n      themeId={themeId}\n    />\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorLooper group="primary" render={({
          id
        }) => <ColoredPaper bordered scrollable colorId={id} container="panel" key={id} style={{
          height: '45px'
        }} mdxType="ColoredPaper">
            <div style={{
            height: '60px'
          }}>The quick brown fox jumped over the lazy dog {id}</div>
          </ColoredPaper>} themeId={themeId} mdxType="ColorLooper" />}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "styled-paper"
    }}>{`Styled paper`}</h2>
    <Playground __position={19} __code={'<ThemeWrapper>\n  <StyledPaper\n    bordered\n    scrollable\n    backgroundColor=\"#556270\"\n    borderColor=\"#000\"\n    container=\"panel\"\n    focusedOutlineColor=\"#f41150\"\n    scrollbarColor=\"#ccc\"\n    style={{ height: \'45px\' }}\n    textColor=\"#fff\"\n  >\n    <div style={{ height: \'60px\' }}>\n      The quick brown fox jumped over the lazy dog\n    </div>\n  </StyledPaper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StyledPaper bordered scrollable backgroundColor="#556270" borderColor="#000" container="panel" focusedOutlineColor="#f41150" scrollbarColor="#ccc" style={{
          height: '45px'
        }} textColor="#fff" mdxType="StyledPaper">
      <div style={{
            height: '60px'
          }}>The quick brown fox jumped over the lazy dog</div>
    </StyledPaper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "responsive-paper-sizes"
    }}>{`Responsive paper sizes`}</h2>
    <h3 {...{
      "id": "page-paper"
    }}>{`Page paper`}</h3>
    <Playground __position={20} __code={'<ThemeWrapper>\n  <Paper bordered color=\"primary\" container=\"page\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="primary" container="page" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "panel-paper"
    }}>{`Panel paper`}</h3>
    <Playground __position={21} __code={'<ThemeWrapper>\n  <Paper bordered color=\"primary\" container=\"panel\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="primary" container="panel" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "popover-paper"
    }}>{`Popover paper`}</h3>
    <Playground __position={22} __code={'<ThemeWrapper>\n  <Paper bordered color=\"primary\" container=\"popover\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="primary" container="popover" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "banner-paper"
    }}>{`Banner paper`}</h3>
    <Playground __position={23} __code={'<ThemeWrapper>\n  <Paper bordered color=\"primary\" container=\"banner\">\n    Hello world\n  </Paper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Paper,
      ColoredPaper,
      StyledPaper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Paper bordered color="primary" container="banner" mdxType="Paper">
      Hello world
    </Paper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "paper"
    }}>{`Paper`}</h3>
    <Props of={Paper} mdxType="Props" />
    <h3 {...{
      "id": "coloredpaper"
    }}>{`ColoredPaper`}</h3>
    <Props of={ColoredPaper} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      